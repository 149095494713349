import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { motion } from 'framer-motion'
import Layout from '../components/Layout'
import PageBanner from '../components/PageBanner'
import SEO from '../components/SEO'
import { Container, Grid, defaultTheme } from '../styles'
import Result from '../components/Result'
import styled from 'styled-components'

const transition = {
    duration: 0.25,
    ease: [0.43, 0.13, 0.23, 0.96]
}

const container = {
    hidden: { opacity: 0, y: 20 },
    show: {
        opacity: 1,
        y: 0,
        transition: {
            staggerChildren: 0.20,
            ...transition
        }
    }
}

const item = {
    hidden: { opacity: 0 },
    show: { opacity: 1 }
}

const PracticeAreasPage = () => {

    const data = useStaticQuery(graphql`
        {
            allResultsJson {
                nodes {
                    courtHouse
                    resultBody
                    title
                    amount
                }
            }
        }
    `)

    return (
        <Layout>
            <SEO
                title="Case Results | Law Office of Craig Charles | West Hollywood"
                metaDesc="Being injured due to someone's negligence is deeply traumatic. Contact us today so we can fight zealously to ensure you’re fairly compensated."
            />
            <PageBanner title="Results" subtitle="Verdicts & Settlements" />
                <ResultsPageBody>
                    <Container>
                        <Grid threeCol
                            variants={container}
                            initial="hidden"
                            animate="show"
                        >
                            {data.allResultsJson.nodes.map(resultItem => (
                                <motion.div variants={item} key={resultItem.body}>
                                    <Result
                                        name={resultItem.title}
                                        courtName={resultItem.courtHouse}
                                        resultAmount={resultItem.amount}
                                        body={resultItem.resultBody}
                                    />
                                </motion.div>
                            ))}
                        </Grid>
                    </Container>
                </ResultsPageBody>
        </Layout>
    )
}

export default PracticeAreasPage

const ResultsPageBody = styled.section`
    padding: 48px 0;
    background: ${defaultTheme.lightBlue};

    @media(min-width: 900px) {
        padding: 96px 0;
    }

    ${Grid} {
        > div {
            margin-bottom: 24px;
            @media(min-width: 900px) {
                margin-bottom: 32px;
            }
        }
    }
`;